<template>
<div>
  <b-container fluid>
    <b-row class="my-5">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Keywords trend detection</h1>
        <p>Explore trends and emerging topics based on AI generated keywords.
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <div v-for="keyword in keywords" :key="'keyword' + keyword.name">
          <b-link :to="{name: 'Keyword', params: { keyword: encodeURIComponent(keyword.name) }}">{{ keyword.count }} - {{ keyword.name }}</b-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'KeywordsHome',
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      loading: true,
      keywords: [],
      limit: 100,
      news: [],
      permissions: {
        create: false,
        read: false,
        update: false,
        delete: false
      }
    }
  },
  created: async function () {
    this.permissions.create = ac.can(this.user.acgroups).createAny('keyword').granted
    this.permissions.read = ac.can(this.user.acgroups).readAny('keyword').granted
    this.permissions.update = ac.can(this.user.acgroups).updateAny('keyword').granted
    this.permissions.delete = ac.can(this.user.acgroups).deleteAny('keyword').granted
    this.load()
  },
  methods: {
    load: async function () {
      this.loading = true
      const response = await this.$Amplify.API.get('cosmos', `/keywords/top/${this.limit}`)
      this.keywords = response
      this.loading = false
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.link-card {
  font-size: 16px;
  font-weight: bold;
}
</style>
